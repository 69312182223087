import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { LoginResultModel } from '@shared/models/login-result.model';
import * as userActions from '@store/actions/user';
import _omit from 'lodash-es/omit';
import { Observable, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as notificationsActions from '@store/actions/notifications';

import { ResetPasswordModel } from '../models/reset-password.model';
import { UserLoginModel } from '../models/user-login.model';
import { UserSignUpModel } from '../models/user-sign-up.model';
import { UserModel } from '../models/user.model';
import { AccountResource } from '../resources/account.resource';
import { dataURLtoBlob } from '../utils';
import { ActiveUserService } from './active-user.service';
import { LocalStorageService } from './local-storage.service';
import { SocketHandlerService } from './socket-handler.service';
import { environment } from '@environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private accountResource: AccountResource,
    private http: HttpClient,
    private activeUserService: ActiveUserService,
    private socketHandlerService: SocketHandlerService,
    private localStorageService: LocalStorageService,
    private storageService: StorageService,
    private store: Store<IState>
  ) {}

  loginSuccess(loginResult) {
    this.setActiveUser(loginResult);
    this.storageService.clear();
    this.socketHandlerService.start();
    // this is just there until the whole login flow is refactored to use ngrx
    this.store.dispatch(notificationsActions.NotificationsHeadRequest());
    this.store.dispatch(userActions.LoginSuccess());
  }

  login(model: UserLoginModel) {
    this.socketHandlerService.stop();

    return this.accountResource
      .login(model)
      .pipe(tap(this.loginSuccess.bind(this)));
  }

  loginViaProvider(
    provider: string,
    providerId,
    email: string,
    firstName: string,
    lastName: string,
    avatarUrl: string
  ) {
    return this.accountResource
      .loginViaProvider(
        provider,
        providerId,
        email,
        firstName,
        lastName,
        avatarUrl
      )
      .pipe(tap(this.loginSuccess.bind(this)));
  }

  signUp(model: UserSignUpModel) {
    return this.accountResource
      .signUp(model)
      .pipe(tap(this.loginSuccess.bind(this)));
  }

  setActiveUser(loginResult: LoginResultModel) {
    this.localStorageService.set(
      'authToken',
      loginResult.Authorization.AccessToken
    );
    const user = _omit(loginResult, 'Authorization') as UserModel;
    this.activeUserService.setActiveUser(user);
  }

  forgotPassword(email: string) {
    return this.accountResource.forgotPassword(email);
  }

  resetPassword(userData: ResetPasswordModel) {
    return this.accountResource.resetPassword(userData);
  }

  update(user: UserModel) {
    return new Observable((o: Observer<any>) => {
      return this.accountResource.update(user).subscribe(
        () => {
          o.next(
            this.activeUserService.update({
              Email: user.Email,
              FirstName: user.FirstName,
              LastName: user.LastName,
              Nickname: user.Nickname,
            })
          );
          return o.complete();
        },
        (err) => {
          return o.error(err);
        }
      );
    });
  }

  changeProfilePicture(url?: string) {
    return new Observable((o: Observer<any>) => {
      return this.accountResource.changeAvatar(url).subscribe(
        () => {
          o.next(
            this.activeUserService.update({
              AvatarUrl: url,
            })
          );
          return o.complete();
        },
        (err) => {
          return o.error(err);
        }
      );
    });
  }

  uploadProfilePicture(croppedImage: string) {
    const formData = new FormData();
    formData.append(
      'file',
      dataURLtoBlob(croppedImage, environment.maxImageUploadSize)
    );
    return this.http.post<string>('images/useravatar', formData);
  }

  suggestAccount() {
    return this.accountResource.suggestAccount();
  }

  deleteAccount() {
    return this.accountResource.deleteAccount();
  }
}
