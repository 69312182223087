import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ArticlesEnum } from '@models/articles';
import { ContentService } from '@services/content.service';
import { ModalService } from '@services/modal.service';
import { getErrorMessage } from '@utils/errors';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import _isString from 'lodash-es/isString';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Component({
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()"
            ><ion-icon slot="icon-only" name="close-outline"></ion-icon
          ></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-progress-bar type="indeterminate" *ngIf="isLoading">
      </ion-progress-bar>
      <ion-item color="danger" *ngIf="error">
        <ion-label class="ion-text-wrap"> {{ error }} </ion-label>
      </ion-item>
      <div class="ion-padding" [innerHTML]="content"></div>
    </ion-content>
  `,
  styles: [
    `
      :host ::ng-deep * {
        color: var(--ion-text-color) !important;
      }
    `,
  ],
})
export class DocumentPresenterModal implements OnInit {
  @Input() articleId: ArticlesEnum;
  isLoading = false;
  title = '';
  error = '';
  content: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private contentService: ContentService
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.isLoading = true;
    this.contentService
      .get(this.articleId)
      .pipe(
        map((response) => {
          // KEEPING LOGIC FROM DOCUMENT PRESENTER TO AVOID REGRESSION
          if (response?.Title) {
            this.title = response.Title;
          }
          if (response.Html) {
            if (_isString(response.Html)) {
              this.content = this.sanitizer.bypassSecurityTrustHtml(
                response.Html.replace(
                  /(src=")(?!(https?:)?\/\/)\/?/gi,
                  '$1https://www.neptunecigar.com/'
                ).replace(/(src="\/\/)/gi, 'src="https://')
              );
            }
          }
        }),
        catchError((e: HttpErrorResponse) => {
          this.error = getErrorMessage(e);
          return of(e);
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
