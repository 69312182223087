import { BaseModel } from './base.model';
import _isString from 'lodash-es/isString';

export class SocketMessageModel extends BaseModel {
  constructor(data: Object) {
    super(data);
    this.parseData();
  }

  Action: string;
  Channel: string;
  CreatedOnUTC: string;
  Data: any;
  Entity: string;
  Id: number;
  Source: any;
  UUID: string;
  UserId: string;

  private parseData() {
    if (_isString(this.Data)) {
      this.Data = JSON.parse(this.Data);
    }
  }
}
