import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DocumentPresenterModal } from '@components/document-presenter/document-presenter.modal';
import { LoginComponent } from '@components/login.component';
import { ArticlesEnum } from '@models/articles';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { UserSignUpModel } from '@shared/models/user-sign-up.model';
import { AccountService } from '@shared/services/account.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { LoaderService } from '@shared/services/loader.service';
import { finalize, tap } from 'rxjs';
import { addIcons } from 'ionicons';
import { closeOutline, readerOutline } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonCheckbox,
} from '@ionic/angular/standalone';

function MatchPassword(password: string, confirmPassword: string) {
  return (formGroup: FormGroup) => {
    const passwordControl = formGroup.controls[password];
    const confirmPasswordControl = formGroup.controls[confirmPassword];
    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (
      confirmPasswordControl.errors &&
      !confirmPasswordControl.errors.passwordMismatch
    ) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  };
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoginComponent,
    RxLet,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonCheckbox,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Register</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <form
        class="max-w-2xl mx-auto"
        [formGroup]="formGroup"
        (ngSubmit)="submit()"
      >
        <ion-list lines="full">
          <ion-item *rxLet="formGroup.get('FirstName'); let control">
            <ion-input
              label="First Name *"
              labelPlacement="floating"
              type="text"
              autocomplete="given-name"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : control.hasError('min')
                    ? 'minimum value is 1'
                    : control.hasError('max')
                    ? 'maximum value is 100'
                    : ''
                  : ''
              "
            >
            </ion-input>
          </ion-item>

          <ion-item *rxLet="formGroup.get('LastName'); let control">
            <ion-input
              label="Last Name *"
              labelPlacement="floating"
              type="text"
              autocomplete="family-name"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : ''
                  : ''
              "
            >
            </ion-input>
          </ion-item>

          <ion-item *rxLet="formGroup.get('Nickname'); let control">
            <ion-input
              label="Nickname"
              labelPlacement="floating"
              type="text"
              name="nick-name"
              autocomplete="off"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('minlength')
                    ? 'Minimum 3 characters'
                    : ''
                  : ''
              "
            >
            </ion-input>
          </ion-item>

          <ion-item *rxLet="formGroup.get('Email'); let control">
            <ion-input
              label="Email *"
              labelPlacement="floating"
              type="email"
              autocomplete="email"
              email
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : control.hasError('email')
                    ? 'Invalid Email'
                    : ''
                  : ''
              "
            ></ion-input>
          </ion-item>

          <ion-item *rxLet="formGroup.get('Password'); let control">
            <ion-input
              label="Password *"
              labelPlacement="floating"
              type="password"
              autocomplete="new-password"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : control.hasError('minlength')
                    ? 'Minimum 6 characters'
                    : ''
                  : ''
              "
            >
            </ion-input>
          </ion-item>

          <ion-item *rxLet="formGroup.get('ConfirmPassword'); let control">
            <ion-input
              label="Confirm Password *"
              labelPlacement="floating"
              type="password"
              autocomplete="new-password"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : control.hasError('passwordMismatch')
                    ? 'Passwords do not match'
                    : ''
                  : ''
              "
            ></ion-input>
          </ion-item>

          <ion-item *rxLet="formGroup.get('AgreedToTerms'); let control">
            <ion-checkbox
              labelPlacement="end"
              slot="start"
              [formControl]="control"
            >
              I agree to terms and conditions
            </ion-checkbox>
            <ion-button
              slot="end"
              color="primary"
              fill="clear"
              (click)="openEula()"
            >
              <ion-icon slot="icon-only" name="reader-outline"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item
            color="danger"
            *ngIf="
              formGroup.touched &&
              formGroup.get('AgreedToTerms').hasError('required')
            "
          >
            You must agree with our terms and conditions
          </ion-item>
        </ion-list>
        <ion-button expand="block" size="large" type="submit" class="mt-5">
          Register
        </ion-button>
      </form>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterModal implements OnInit {
  @Input() email = '';

  formGroup = this.fb.nonNullable.group(
    {
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Nickname: ['', [Validators.minLength(3)]],
      Email: ['', [Validators.email, Validators.required]],
      Password: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: ['', [Validators.required]],
      AgreedToTerms: [false, Validators.requiredTrue],
    },
    {
      validators: [MatchPassword('Password', 'ConfirmPassword')],
    }
  );

  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private accountService: AccountService,
    private loader: LoaderService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef
  ) {
    addIcons({ closeOutline, readerOutline });
  }

  ngOnInit() {
    if (this.email) {
      this.formGroup.get('Email').setValue(this.email);
      this.cdr.markForCheck();
    }
  }
  dismiss() {
    this.modalService.dismiss();
  }

  async openEula() {
    const modal = await this.modalService.createCSModal(
      DocumentPresenterModal,
      {
        articleId: ArticlesEnum.EULA,
      },
      {
        mobile: {
          initialBreakpoint: 0.5,
          breakpoints: [0, 0.5, 1],
        },
      }
    );
    await modal.present();
  }

  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.loader.show();

    this.analyticsService.register();

    this.accountService
      .signUp(this.formGroup.value as UserSignUpModel)
      .pipe(
        tap(() => {
          this.modalService.dismiss({}, 'success');
        }),
        finalize(() => {
          this.loader.hide();
        })
      )
      .subscribe();
  }
}
