import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { addIcons } from "ionicons";
import { personCircleOutline } from "ionicons/icons";
import { IonButton, IonIcon, IonSpinner } from "@ionic/angular/standalone";

@Component({
    selector: 'follow-button',
    standalone: true,
    imports: [CommonModule, IonButton, IonIcon, IonSpinner],
    template: `
    <ion-button
      [disabled]="isLoading"
      (click)="toggle.emit($event)"
      size="small"
      [fill]="isFollowed ? 'solid' : 'outline'"
      [color]="isFollowed ? 'primary' : 'medium'"
    >
      <ion-icon
        *ngIf="!isLoading; else spinner"
        slot="start"
        name="person-circle-outline"
      ></ion-icon>
      <ng-template #spinner>
        <ion-spinner></ion-spinner>
      </ng-template>
      {{ !!isFollowed ? 'FOLLOWING' : 'FOLLOW' }}
    </ion-button>
  `,
    styles: [
        `
      ion-spinner {
        width: 19px;
        height: 19px;
        margin-right: 5px;
      }
    `,
    ],
})
export class FollowButtonComponent implements OnInit {
    @Input() isFollowed = false;
    @Input() isLoading = false;
    @Output() toggle = new EventEmitter<Event>();

    constructor() {
        addIcons({ personCircleOutline });
    }

    ngOnInit() { }
}
