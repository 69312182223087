import { Injectable } from '@angular/core';
import {
  FacebookError,
  FacebookGetLoginStatusResponse,
  FacebookGetProfileResponse,
  FacebookLogin,
  FacebookLoginResponse,
} from '@capacitor-community/facebook-login';
import debug from 'debug';

declare interface Facebook {
  init(options: {
    appId: string;
    autoLogAppEvents: boolean;
    xfbml: boolean;
    version: string;
  }): void;

  login(handle: (response: any) => void, options?: { scope: string }): void;

  logout(handle: (response: any) => void): void;

  api<TResponse>(path: string, callback: (response: TResponse) => void): void;
  api<TParams extends object, TResponse>(
    path: string,
    params: TParams,
    callback: (response: TResponse) => void
  ): void;
  api<TParams extends object, TResponse>(
    path: string,
    method: 'get' | 'post' | 'delete',
    params: TParams,
    callback: (response: TResponse) => void
  ): void;
}

declare var FB: Facebook;

export interface FBUser {
  id: string;
  email: string;
  picture: any;
  first_name: string;
  last_name: string;
}

const log = debug('FacebookAuthService');
const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
@Injectable({
  providedIn: 'root',
})
export class FacebookAuthService {
  constructor() {}

  async logout() {
    try {
      const result = await FacebookLogin.getCurrentAccessToken();
      log('logout', { result });
      if (result.accessToken) {
        return FacebookLogin.logout();
      }
      return Promise.reject();
    } catch (error) {
      // if not logged in with FB, we do not want to catch the error
      log('logout', { error });
      return Promise.reject();
    }
  }

  async login() {
    const result = (await FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS,
    })) as FacebookLoginResponse;

    if (result.accessToken) {
      const user = await FacebookLogin.getProfile({
        fields: ['id', 'email', 'picture', 'first_name', 'last_name'],
      });

      return user as unknown as FBUser;
    }
    return Promise.reject();
  }

  /**
   * The web login must use callbacks instead of asynchronous tasks in order to work on iOS
   * Safari refuses to open a window if within an async task.
   * https://github.com/capacitor-community/facebook-login/issues/70
   */
  loginWeb(
    success: (user: FBUser) => void,
    failure: (error?: FacebookError) => void
  ) {
    FB.login(
      (response) => {
        console.debug('FB.login', response);

        if (
          response.status === 'connected' &&
          response.authResponse.accessToken
        ) {
          FB.api<{ fields: string }, FBUser & FacebookGetProfileResponse>(
            '/me',
            {
              fields: [
                'id',
                'email',
                'picture',
                'first_name',
                'last_name',
              ].join(','),
            },
            (user) => {
              if (user.error) {
                failure(user.error);
                return;
              }

              success(user);
            }
          );
        } else {
          failure();
        }
      },
      { scope: FACEBOOK_PERMISSIONS.join(',') }
    );
  }
}
